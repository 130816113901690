/*
 * TimeLimit Server Admin UI
 * Copyright (C) 2020 Jonas Lochmann
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

.container {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.border {
  border: 1px solid black;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
}

form {
  margin-bottom: 0;
}

.cardtitle {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

input[type=submit], button {
  color: black;
  margin-top: 1rem;
  margin-right: 0.5rem;
  padding: 0.5em 1em;
  background-color: white;
  border: 1px solid black;
  border-radius: 1rem;
}

input[type=submit]:hover:not([disabled]), button:hover:not([disabled]) {
  background-color: grey;
  border-color: grey;
  color: white;
}

input[type=submit][disabled], button[disabled] {
  background-color: #bbb;
}

input:not([type=submit]):not([type=radio]), textarea {
  margin: 0.5rem 0;
  border: 1px solid grey;
  border-radius: 1rem;
  padding: 0.5em 1rem;
  width: 100%;
}

input:not([type=submit]):focus:not([type=radio]):not(:disabled), textarea:focus:not(:disabled) {
  border-color: black;
}

input:not([type=submit]):disabled:not([type=radio]), textarea:disabled {
  background-color: #ccc;
}

.radiocontainer {
  display: block;
  margin-top: 0.5rem;
}

.radioicon {
  display: inline-block;
  width: calc(1em - 2px);
  height: calc(1em - 2px);
  border: 1px solid black;
  border-radius: 0.5em;
  margin: 0 0.5em;
}

.radiocontainer > input:checked ~ .radioicon {
  background-color: black;
}

.radiocontainer:hover > input:not(:checked) ~ .radioicon {
  background-color: #ddd;
}

.radiocontainer > input {
  display: none;
}

.logcontainer {
  display: block;
}

.logcontainer > span {
  display: block;
  margin: 0.5rem 0;
}

.statustext {
  display: block;
  width: 100%;
  max-height: 50vh;
  overflow: auto;
  white-space: pre;
  scrollbar-color: black grey;
  scrollbar-width: thin;
}
